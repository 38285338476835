<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="">
       <div class="row">    
              <div class="col-md-8 col-12">
                 <div class="card-header">
                <h3 class="card-title"> Order History List </h3>
              </div>
              </div>
              <div class="col-md-12 col-12">
                 <div class="srcpadmobie"> 
                 <div class="row">
                   <div class="col-md-3 col-xs-12"> 
             <input class="form-control " type="text" v-model="searchQuery" placeholder="Search" />
          </div>
          <div class="col-md-4 col-5">               
<input type="date" class="form-control topm5" name="date1" id="date1" v-model="date1"/>           
          </div>
            <div class="col-md-4 col-5">              
<input type="date" class="form-control topm5" name="date2" id="date2" v-model="date2"/>          
          </div>
          <div class="col-md-1 col-2">  
            <button v-on:click="getData()" class="btn btn-success btn-sm topm5"><i class="fas fa-search"></i></button>
          </div>
                 </div>
                 </div>
              </div>
            </div> 
            <div class="hgtblank">&nbsp;</div>
      </div>
      
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <section class="col-lg-12 connectedSortable">
            <div class="">              
              <div class="">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
                                     <div class="row">
<div class="col-md-12">
<div class="card card-primary cardpad">              
              <!-- /.card-header -->
<div class="card-body">

<table class="table">
  <tr>
    <th>Order id</th>
    <th>Name</th>
    <th>Mobile</th>
    <th>Date</th>
    <th>Total Amount</th>
    <th>Due Amount</th>
    <th v-if="this.app_type != '0'">Action</th>
  </tr>
  <tr v-for="(user) in filteredResources" :key="user.id">
    <td><router-link class="linkdark" :to="{ path: '/vieworder/'+ user.id}" v-bind:tooltip="Alignment" append><a>#{{ user.invoice_no }}</a></router-link></td>
    <td>{{ user.name }}</td>
    <td>{{ user.mobile_no }}</td>
    <td>{{ user.pickup_date }}</td>
    <td>Rs. {{ user.grand_amount }}</td>
    <td>Rs. {{ user.balance }}</td>
    <td v-if="this.app_type != '0'">
      <div class="row">
<div class="col-lg-12 col-xs-12">
<button v-if="user.order_status == 'Delivered'" type="button" class="btn btn-outline-danger btn-xs">{{user.order_status}}</button>
<button v-if="user.order_status == 'Pending'" type="button" class="btn btn-outline-success btn-xs">{{user.order_status}}</button>

</div>
</div>
    </td>
  </tr>
</table>  

              </div>
              <!-- /.card-body -->
            </div>

        </div>
      </div>  
                      <div class="hello">
                        <VueTailwindPagination
      :current="currentPage"
      :total="total"
      :per-page="perPage"
      @page-changed="onPageClick($event)"
    />
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";

export default {   
   components:{     
     VueTailwindPagination,
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar        
      },
    data() {          
      return {
        company_id:this.$store.state.auth.user.data[0].company_id,
        app_type:this.$store.state.auth.user.data[0].app_type, 
        token: localStorage.token,
        quote: '',
        myData: null,
        resources: [],
        showbtn:this.id, 
        searchQuery:'',
        currentPage: '1',
        perPage: '10',
        total: this.total,  
        date1:this.date1,  
        date2:this.date2,                
        }
    },    
    
  computed: {
    filteredResources (){
      if(this.searchQuery){
        //alert(this.date1);
      return this.resources.filter((user)=>{       
        return user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
        user.mobile_no.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
        user.invoice_no.toLowerCase().includes(this.searchQuery.toLowerCase())        
      })
      }else{
        return this.resources;
      }
    },
  },
  methods: {              
      async getData() { 
      this.date11 = this.date1 
      this.date22 = this.date2             
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/invoice-list`, {
            user_id:this.$store.state.auth.user.data[0].id, 
            company_id: this.company_id,
            page: this.currentPage, 
            order_type: '',
            client_id: '',
            from_date: this.date11,
            to_date: this.date22,
            search_data: ''                 
        });      
      this.resources = response.data.data.client_list;
      this.total = response.data.total_record;
      console.log(this.total)
    },  
    onPageClick(event) {
      this.currentPage = event;
      this.getData(this.currentPage);
    },
    show(id){     
      this.showbtn = id;
    },
    showclose(){          
      this.showbtn = false;
    }  
  },

  mounted(){   
    setTimeout(() => {
      this.myData = 'Example Data';
    }, 2000);  
    this.getData();
    
  }
      

  };
</script>

<style>
.vuejs3-datepicker__value {padding: 7px 15px;min-width: 256px;border: 1px solid #ced4da;}
.vuejs3-datepicker__calendar-topbar {
    background-color: #40b983;
    color: #fff;
    border-radius: 4px 4px 0 0;
    padding: 25px;
    display: none;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem 1.25rem 1.25rem !important;
}
.datef { position: relative; left: 54px; top: -54px;}
  @import '../../assets/dist/css/custom.css'  
</style>